<template>
<div>
    <b-row class="mb-3">
        <b-col>
            Välj vinstmall
        </b-col>
        <b-col>
            <b-select :options="templates" v-model="selected"></b-select>
        </b-col>
    </b-row>
    <hr />
    <div v-if="currentTemplate.length > 0" class="mt-3" style="max-width:300px;margin:auto">
        <b-row class="table-header">
            <b-col>Placering</b-col>
            <b-col class="text-right">Vinst %</b-col>
        </b-row>
        <b-row v-for="(row, index) in currentTemplate" :key="index">
            <b-col>{{row.placement}}</b-col>
            <b-col class="text-right">{{row.value}} %</b-col>
        </b-row>
        <b-row class="table-footer">
            <b-col>Totalt</b-col>
            <b-col class="text-right">{{totalp}} %</b-col>
        </b-row>
    </div>
    <div v-else-if="selected == 'custom'">
        <p>Skapa dina egna vinstrader med SEK</p>
        <div class="font-weight-bold">Antal vinnare:</div>
        <b-form-input type="number" v-model="custom_nr_users" placeholder="Antal vinnare..."></b-form-input>
    </div>

    <div class="mt-4 text-center">
        <b-button class="m-1" variant="secondary" @click="Cancel()">Avbryt</b-button>
        <b-button class="m-1" variant="success" @click="Add()">Lägg till</b-button>
    </div>
</div>
</template>

<script>
import winnertemplates from "@/helpers/values/winners";

export default {
    Name: "AddTemplate",
    components: {},
    data() {
        return {
            selected: false,
            ticks: 1,
            totalp: 0,
            custom_nr_users: 1,
        }
    },
    computed: {
        templates() {
            var list = [];
            for (var nrusers in winnertemplates.templates) {
                list.push({
                    text: nrusers + " användare (%)",
                    value: nrusers
                })
            }
            list.push({
                text: "Egen (SEK)",
                value: "custom"
            });
            return list;
        },
        currentTemplate() {
            var template = [];
            this.totalp = 0;
            if (this.selected) {
                var ctemp = winnertemplates.templates[this.selected];
                for (var place in ctemp) {
                    template.push({
                        placement: place,
                        value: ctemp[place],
                        valuetype: 1
                    });
                    this.totalp += ctemp[place];
                }
            }
            return template;
        }
    },
    methods: {
        Add() {
            if (this.selected) {
                var data = [];
                if(this.selected == "custom"){
                    // egen                    
                    for(var i = 0; i < this.custom_nr_users; i++)
                    {
                        data.push({placement: (i+1), value: 0, valuetype: 0});
                    }
                }
                else {
                    data = this.currentTemplate;
                }
                this.$emit("add", data);
            }
        },
        Cancel() {
            this.$emit("cancel");
        }
    },
};
</script>
